
// utils
import { freeze } from '@@/common/assets/js/utils/common-utils';

// components
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';
import HomeMobileSlider from 'landing/components/pages/home/HomeMobileSlider.vue';

export default {
    name: 'HomeAbout',

    components: {
        VImageSrcSet,
        HomeMobileSlider,
    },

    props: {
        aboutData: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            slider: null,
            mobileAnimation: null,
            timeline: null,
            customOptions: {
                breakpoints: {
                    320: {
                        slidesPerView: 2.6,
                        spaceBetween: 4,
                        centeredSlides: true,
                        initialSlide: 1,
                    },

                    768: {
                        slidesPerView: 3,
                        spaceBetween: 8,
                        centeredSlides: false,
                        initialSlide: 0,
                    },
                },
            },
        };
    },

    computed: {
        images() {
            return this.aboutData?.gallery || [];
        },
    },

    async mounted() {
        await freeze(150);

        requestAnimationFrame(this.initAnimation);
    },

    beforeDestroy() {
        if (this.timeline) {
            this.timeline.kill();
        }
    },

    methods: {
        handleIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.$nextTick(() => {
                    if (this.timeline) {
                        this.timeline.play();
                    }
                });
            } else if (this.timeline) {
                this.timeline.pause();
            }
        },

        initAnimation() {
            if (!this.$deviceIs.pc || !this.$refs.cards?.length) {
                return;
            }
            const cards = this.$refs.cards;

            const gsapMatchMedia = this.$gsap.matchMedia();

            gsapMatchMedia.add('(min-width: 1280px)', () => {
                this.timeline = this.$gsap.timeline({
                    repeat: -1, // Бесконечное повторение
                    repeatDelay: 0,
                    paused: true,
                });

                cards.forEach((card, i) => {
                    if (i % 2 === 0) {
                        this.timeline
                            .from(card, {
                                scale: 0,
                                opacity: 0,
                                duration: 1.5,
                                ease: 'power4.in',
                            })
                            .to(card, {
                                scale: .2,
                                opacity: 0,
                                duration: 1.5,
                                ease: 'power4.in',
                            });
                    } else {
                        this.timeline
                            .from(card, {
                                scale: 0,
                                opacity: 0,
                                duration: 1.5,
                                ease: 'power4.in',
                            }, '-=1.7')
                            .to(card, {
                                scale: .2,
                                opacity: 0,
                                duration: 1.5,
                                ease: 'power4.in',
                            });
                    }
                });

                return () => {
                    this.$gsap.set(this.$el, { clearProps: true });
                    this.timeline.kill();
                };
            });
        },
    },
};
