
// Utils
import { debounce } from '@@/common/assets/js/utils/common-utils';

// components
import VSwiper from '~/components/ui/swiper/VSwiper.vue';
import VCursor from 'landing/components/common/VCursor.vue';
import EventCard from '~/components/common/EventCard.vue';

export default {
    name: 'HomeEventsSlider',

    components: {
        VSwiper,
        VCursor,
        EventCard,
    },

    props: {
        slides: {
            type: Array,
            default: () => [],
        },

        values: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            activeIndex: null,
            debouncedOnSetIndex: null,
            cursorIsVisible: true,
        };
    },

    created() {
        this.debouncedOnSetIndex = debounce(this.setActiveIndex, 200);
    },

    methods: {
        setActiveIndex(index) {
            if (!this.$deviceIs.pc || this.activeIndex === index) {
                return;
            }

            this.activeIndex = index;
        },
    },
};
