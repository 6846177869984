// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VScrollBox_OY6nH{overflow:hidden;position:relative}.VScrollBox_OY6nH .wrapper_w--XZ{bottom:0;box-sizing:border-box;height:100%;left:0;max-height:100%;max-width:100%;overflow:auto;position:absolute;right:0;scrollbar-width:none!important;top:0;width:auto;z-index:0;-ms-overflow-style:none!important}.VScrollBox_OY6nH .wrapper_w--XZ::-webkit-scrollbar{height:0!important;width:0!important}.VScrollBox_OY6nH .wrapper_w--XZ._offset_QEGrJ{padding-right:.4rem}.VScrollBox_OY6nH .placeholder_D1wYS{max-height:100%;max-width:100%;pointer-events:none;width:100%}.VScrollBox_OY6nH .fadeMask_RDjIU{height:100%;position:absolute;top:0;width:100%}.VScrollBox_OY6nH .fadeMask_RDjIU._top:before{min-height:0}.VScrollBox_OY6nH .fadeMask_RDjIU._bottom:after{min-height:0}.VScrollBox_OY6nH .fadeMask_RDjIU._show:after,.VScrollBox_OY6nH .fadeMask_RDjIU._show:before{display:block}.VScrollBox_OY6nH .fadeMask_RDjIU:after,.VScrollBox_OY6nH .fadeMask_RDjIU:before{content:\"\";display:none;left:1px;min-height:4rem;pointer-events:none;position:absolute;transition:min-height .2s linear;width:calc(100% - 1.2rem);z-index:2}.VScrollBox_OY6nH .fadeMask_RDjIU:before{background:linear-gradient(180deg,#fff 12%,#fffc 50%,#fff0);top:0}.VScrollBox_OY6nH .fadeMask_RDjIU:after{background:linear-gradient(0deg,#fff 12%,#fffc 50%,#fff0);bottom:0}.scrollbar_MhAfi{bottom:0;overflow:hidden;position:absolute;right:0;width:.6rem;z-index:3}.scrollbar_MhAfi._horizontal_NYX5V{left:0}.scrollbar_MhAfi._horizontal_NYX5V .thumb_oDiHz{bottom:.1rem;height:.4rem;left:0}.scrollbar_MhAfi._vertical_-djel{margin:1rem .3rem 1rem 0;top:0}.scrollbar_MhAfi._vertical_-djel .thumb_oDiHz{cursor:pointer;right:.1rem;top:0;transition:opacity .3s ease;width:.4rem}.scrollbar_MhAfi._vertical_-djel .thumb_oDiHz:hover{opacity:.7}.scrollbar_MhAfi .thumb_oDiHz{background-color:#697180;border-radius:.4rem;position:absolute}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"VScrollBox": "VScrollBox_OY6nH",
	"wrapper": "wrapper_w--XZ",
	"_offset": "_offset_QEGrJ",
	"placeholder": "placeholder_D1wYS",
	"fadeMask": "fadeMask_RDjIU",
	"scrollbar": "scrollbar_MhAfi",
	"_horizontal": "_horizontal_NYX5V",
	"thumb": "thumb_oDiHz",
	"_vertical": "_vertical_-djel"
};
module.exports = ___CSS_LOADER_EXPORT___;
