// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".slider_v2ol9{display:flex;flex-direction:column;margin-left:2rem}.slide_BVU0o{width:auto}.controls_gvFTR,.slide_BVU0o._last_PfpI2{margin-right:2rem}.controls_gvFTR{align-items:center;display:grid;gap:1.6rem;grid-template-columns:1fr 5.6rem;margin-top:2.4rem}.scrollbarWrapper_W2kyF{padding:.6rem;position:relative;width:100%;z-index:3}.scrollbar_Be9z7.swiper-scrollbar.swiper-scrollbar-horizontal{background-color:#3a363d;border-radius:unset;bottom:50%;height:1px;transform:translateY(-50%)}.scrollbar_Be9z7.swiper-scrollbar.swiper-scrollbar-horizontal .swiper-scrollbar-drag{-webkit-backface-visibility:hidden;backface-visibility:hidden;background:#fff;border-radius:unset;height:1px;opacity:1;perspective:1000;will-change:transform}.scrollbar_Be9z7.swiper-scrollbar.swiper-scrollbar-horizontal .swiper-scrollbar-drag:after{content:\"\";height:.8rem;left:0;position:absolute;top:50%;transform:translateY(-50%);width:100%}.buttons_bwrzF{align-items:center;display:flex;flex-shrink:0;margin:-1rem;transform:translateX(4px)}.button_wnHzV{color:#fff;cursor:pointer;display:block;padding:1rem}.button_wnHzV._next_pSaw3 .icon_vj2wG{transform:rotate(180deg)}.button_wnHzV:disabled{opacity:.6}.icon_vj2wG{height:1.8rem;width:1.8rem}.separator_BnbLh{background-color:#3a363d;display:block;height:1.8rem;margin:-4px -2px 0;width:1px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"slider": "slider_v2ol9",
	"slide": "slide_BVU0o",
	"controls": "controls_gvFTR",
	"_last": "_last_PfpI2",
	"scrollbarWrapper": "scrollbarWrapper_W2kyF",
	"scrollbar": "scrollbar_Be9z7",
	"buttons": "buttons_bwrzF",
	"button": "button_wnHzV",
	"_next": "_next_pSaw3",
	"icon": "icon_vj2wG",
	"separator": "separator_BnbLh"
};
module.exports = ___CSS_LOADER_EXPORT___;
