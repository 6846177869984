
// components
import StaggeredTitle from '~/components/common/StaggeredTitle.vue';
import OpportunitiesCard from '~/components/common/OpportunitiesCard.vue';
// utils
import { remToPx, freeze } from '@@/common/assets/js/utils/common-utils';
// scss
import scssVars from '~/assets/scss/shared/_variables.scss';

export default {
    name: 'HomeOpportunities',

    components: {
        OpportunitiesCard,
        StaggeredTitle,
    },

    props: {
        opportunitiesData: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            lines: [
                { label: 'Возможности', color: '#20123C' },
                { label: 'клуба', color: '#0F0819' },
            ],

            animation: null,
        };
    },

    computed: {
        cards() {
            return [...this.opportunitiesData?.block_cards || []]?.sort((a, b) => a.order - b.order);
        },
    },

    async mounted() {
        await freeze(150);

        requestAnimationFrame(this.initAnimations);
    },

    beforeDestroy() {
        this.animation?.kill();
    },

    methods: {
        initAnimations() {
            if (!this.$deviceIs.pc) {
                return;
            }

            const gsapMatchMedia = this.$gsap.matchMedia();
            gsapMatchMedia.add('(min-width: 1280px)', () => {
                this.animation = this.$gsap.timeline({
                    scrollTrigger: {
                        anticipatePin: 1,
                        trigger: this.$el,
                        start: () => `top ${remToPx(scssVars[this.$deviceIs.pc ? 'header-h' : 'header-h-mobile'])}`,
                        end: `+=${this.$ScrollTrigger.maxScroll(this.$refs.slider, true) + window.innerHeight / 5}px`,
                        toggleActions: 'restart complete reverse reset',
                        scrub: 1,
                        pin: this.$el,
                        pinSpacing: true,
                    },
                });

                this.animation.to(this.$refs.slider, { x: () => `-=${this.$ScrollTrigger.maxScroll(this.$refs.slider, true)}`, delay: .1, ease: 'linear', duration: 1 })
                    .to(this.$refs.slider, { y: '+=0', delay: .1, duration: 0 });
                return () => {
                    if (this.animation) {
                        this.animation.kill();
                    }
                };
            });
        },
    },
};
