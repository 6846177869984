
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';
import VCursor from 'landing/components/common/VCursor.vue';
import VIcon from 'landing/components/ui/icon/VIcon.vue';
export default {
    name: 'ResidenceSlider',
    components: {
        VIcon,
        VImageSrcSet,
        VCursor,
    },

    props: {
        companiesData: {
            type: Array,
            default: () => [],
        },

        optionSlider: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            slider: null,
        };
    },

    computed: {
        companiesSlides() {
            if (!this.companiesData?.length) {
                return [];
            }

            if (this.companiesData.length < 15) {
                let resArr = [...this.companiesData];
                for (let i = 1; i < Math.ceil(15 / this.companiesData.length); ++i) {
                    resArr = [
                        ...resArr,
                        ...this.companiesData.map(el => ({ ...el, id: Math.random() })),
                    ];
                }

                return resArr;
            }

            return this.companiesData;
        },
    },

    async mounted() {
        this.initSlider();
    },

    methods: {
        initSlider() {
            this.slider = new this.$Swiper(this.$refs.slider, {
                slidesPerView: 'auto',
                loop: true,
                spaceBetween: 4,
                touchStartPreventDefault: false,

                freeMode: {
                    enabled: true,
                },

                autoplay: {
                    delay: 1,
                    disableOnInteraction: true,
                    waitForTransition: false,
                },

                speed: 300,

                modules: [
                    this.$SwiperModules.FreeMode,
                    this.$SwiperModules.Autoplay,
                ],

                ...this.optionSlider,

                breakpoints: {
                    768: {
                        speed: 5000,
                    },
                },
            });
        },

        slideNext() {
            this.slider?.slideNext();
        },

        slidePrev() {
            this.slider?.slidePrev();
        },
    },
};
