
// components
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';
// utils
import { debounce, remToPx, freeze } from '@@/common/assets/js/utils/common-utils';
// scss
import scssVars from '~/assets/scss/shared/_variables.scss';

export default {
    name: 'HomeQuote',

    components: {
        VImageSrcSet,
    },

    props: {
        quote: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            progress: 0,

            linesOfText: [],
            lineHeight: 0,
            currentActiveLine: 0,
            isLoaded: false,

            animationText: null,
            animationPrev: null,
        };
    },

    watch: {
        progress: {
            immediate: true,
            handler(val) {
                if (!this.linesOfText?.length) {
                    this.currentActiveLine = 0;
                }

                this.currentActiveLine = Math.floor(this.progress * this.linesOfText.length);
            },
        },

        '$deviceIs.pc'(val) {
            if (!this.animationText) {
                requestAnimationFrame(this.initAnimations);
                return;
            }

            if (val) {
                if (this.animationPrev) {
                    this.animationPrev?.scrollTrigger?.enable();
                    setTimeout(() => {
                        this.animationPrev?.scrollTrigger?.refresh();
                    }, 100);
                } else {
                    requestAnimationFrame(this.initAnimations);
                    return;
                }
            } else {
                this.animationPrev.scrollTrigger?.disable();
            }

            setTimeout(() => {
                this.animationText.scrollTrigger?.refresh();
            }, 100);
        },
    },

    beforeDestroy() {
        this.animationText?.kill();
        this.animationPrev?.kill();
    },

    async mounted() {
        await freeze(50);

        requestAnimationFrame(this.initAnimations);

        await freeze(100);

        this.calcLines();
        this.isLoaded = true;
    },

    methods: {
        debounce,

        calcLines() {
            if (!this.$refs['hidden-text']) {
                return;
            }

            this.$refs['hidden-text'].innerHTML = this.$refs['hidden-text'].innerHTML.replace(/&nbsp;/g, '&amp;nbsp;');
            const allWords = this.$refs['hidden-text'].innerText.match(/\S+/g) || [];

            const lines = [];
            let currentLine = '';

            for (let i = 0; i < allWords.length; i++) {
                const newLine = currentLine + ' ' + allWords[i];
                this.$refs.test.innerHTML = newLine;

                if (this.$refs.test.clientWidth > this.$refs.text.clientWidth) {
                    lines.push(currentLine.trim());
                    currentLine = allWords[i];
                } else {
                    currentLine = newLine;
                }
            }
            lines.push(currentLine.trim());

            this.linesOfText = lines;
            this.lineHeight = this.$refs['text-wrapper'].offsetHeight / lines.length;
        },

        initAnimations() {
            // анимация затемнения блока перед
            if (this.$deviceIs.pc && !this.animationPrev) {
                this.animationPrev = this.$gsap.to(this.$refs.prev, {
                    opacity: 1,
                    ease: 'power2',

                    scrollTrigger: {
                        anticipatePin: 1,
                        trigger: '#home-about-anchor',
                        start: 'bottom bottom',
                        end: '+=100%',
                        toggleActions: 'restart complete reverse reset',
                        scrub: 1,
                        pin: '#home-about',
                        pinSpacing: false,
                    },
                });
            }

            // анимация текста
            if (!this.animationText) {
                this.animationText = this.$gsap.to(this.$refs.container, {
                    scrollTrigger: {
                        anticipatePin: 1,
                        trigger: this.$refs.container,
                        start: () => `top ${remToPx(scssVars[this.$deviceIs.pc ? 'header-h' : 'header-h-mobile'])}`,
                        end: '+=200%',
                        toggleActions: 'restart complete reverse reset',
                        scrub: 1,
                        pin: this.$el,
                        pinSpacing: false,
                        onUpdate: self => requestAnimationFrame(() => this.progress = self.progress),
                    },
                });
            }
        },
    },
};
