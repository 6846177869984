// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeFeedback_h-Il3{display:flex;flex-direction:column;height:calc(100dvh - 12.2rem);padding-bottom:2.4rem;padding-top:10rem;position:relative}@media(max-width:1279.98px){.HomeFeedback_h-Il3{padding:12rem 0 4.8rem}}.bg_q2WOB{height:100dvh;object-fit:contain;position:absolute;right:0;top:0;width:auto}.title_39ekb{font-family:\"Bebas-Neue\",sans-serif;font-size:6.8rem;font-weight:700;letter-spacing:-.136rem;line-height:1;margin-bottom:6.6rem;z-index:2}@media(max-width:1279.98px){.title_39ekb{font-size:4.4rem;margin:0 1.6rem 4.8rem}}.sliderWrapper_t06Ld{display:flex;flex:1 1 auto;overflow:hidden;padding-bottom:5.4rem;position:relative;z-index:2}@media(max-width:1279.98px){.sliderWrapper_t06Ld{padding-bottom:0}}.slider_Ez4Bs{width:100%}.navigation_UuV5u{align-items:center;bottom:0;display:flex;left:0;position:absolute}@media(max-width:1279.98px){.navigation_UuV5u{display:none}}.navBtn_8mnZL{height:4.2rem;width:4.2rem}.navBtn_8mnZL+.navBtn_8mnZL{margin-left:1.2rem}.navBtnIcon_00WiR{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"HomeFeedback": "HomeFeedback_h-Il3",
	"bg": "bg_q2WOB",
	"title": "title_39ekb",
	"sliderWrapper": "sliderWrapper_t06Ld",
	"slider": "slider_Ez4Bs",
	"navigation": "navigation_UuV5u",
	"navBtn": "navBtn_8mnZL",
	"navBtnIcon": "navBtnIcon_00WiR"
};
module.exports = ___CSS_LOADER_EXPORT___;
