
import ResidenceSlider from 'landing/components/pages/home/residence/ResidenceSlider.vue';
import ResidenceHero from 'landing/components/pages/home/residence/ResidenceHero.vue';
import { freeze } from '@@/common/assets/js/utils/common-utils';

export default {
    name: 'HomeResidence',
    components: {
        ResidenceHero,
        ResidenceSlider,
    },

    props: {
        companiesData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            mm: null,
            animation: null,
        };
    },

    watch: {
        '$deviceIs.pc'(val) {
            this.animation?.clear();
            requestAnimationFrame(() => {
                this.initAnimation();
                this.animation?.scrollTrigger?.refresh();
            });
        },
    },

    beforeDestroy() {
        this.animation?.kill();
    },

    async mounted() {
        await freeze(100);
        requestAnimationFrame(this.initAnimation);
    },

    methods: {
        initAnimation() {
            if (this.$deviceIs.pc) {
                const hero = this.$refs.hero;
                const slider = this.$refs.slider;
                const content = hero.$refs.content;

                this.animation = this.$gsap.timeline({
                    scrollTrigger: {
                        trigger: this.$refs.topAnchor,
                        start: 'top top',
                        endTrigger: this.$refs.bottomAnchor,
                        end: 'bottom bottom',
                        pin: hero.$el,
                        pinSpacing: true,
                        scrub: 1,
                    },
                });

                this.animation
                    .to(slider, { y: 0, duration: .1, ease: 'linear' }, 'start')
                    .to(content, { y: '-10rem', duration: .1, ease: 'linear' }, 'start');
            }
        },

        onNext() {
            this.$refs?.swiper?.slideNext();
        },

        onPrev() {
            this.$refs?.swiper?.slidePrev();
        },
    },
};
