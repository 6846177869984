// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResidenceSlider_oLzBX{cursor:none;overflow:hidden;position:relative}.ResidenceSlider_oLzBX .VCursor_drag .icon_W0vUJ:first-child{transform:translateX(.5rem)}.ResidenceSlider_oLzBX .VCursor_drag .icon_W0vUJ:last-child{transform:translateX(-.5rem) rotate(180deg)}.swiperWrapper_ahbT9{transition-timing-function:linear!important}.sliderTitle_PSTIr{font-family:\"Bebas-Neue\",sans-serif;font-size:3.6rem;font-weight:700;letter-spacing:-.072rem;line-height:1;padding:0 3.6rem}.slider_PD4DI{width:100%}.card_71XRp{align-items:center;background-color:#161119;color:#848286;display:flex;height:22.8rem;justify-content:center;width:22.8rem}@media(max-width:767.98px){.card_71XRp{height:9.6rem;width:9.6rem}}.cardImage_xLbqw{display:block;filter:contrast(0) grayscale(1);height:6.4rem;object-fit:contain;object-position:center;pointer-events:none;-webkit-user-select:none;user-select:none;width:15.6rem}@media(max-width:767.98px){.cardImage_xLbqw{height:2.4rem;width:7.2rem}}.cursor_x5tNy{align-items:center;background:#fff;border-radius:50%;display:flex;height:4.8rem;justify-content:center;width:4.8rem}.cursor_x5tNy .icon_W0vUJ{transition:transform .3s ease}.cursor_x5tNy .icon_W0vUJ:last-child{transform:rotate(180deg)}.icon_W0vUJ{color:#09040d;height:1.8rem;width:1.8rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"ResidenceSlider": "ResidenceSlider_oLzBX",
	"icon": "icon_W0vUJ",
	"swiperWrapper": "swiperWrapper_ahbT9",
	"sliderTitle": "sliderTitle_PSTIr",
	"slider": "slider_PD4DI",
	"card": "card_71XRp",
	"cardImage": "cardImage_xLbqw",
	"cursor": "cursor_x5tNy"
};
module.exports = ___CSS_LOADER_EXPORT___;
