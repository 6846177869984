
// Vuex
import { mapState } from 'vuex';

// components
import EventCard from '~/components/common/EventCard.vue';
import EventFilters from '~/components/common/EventFilters';
import HomeEventsSlider from '~/components/pages/home/HomeEventsSlider.vue';

export default {
    name: 'HomeEvents',

    components: {
        EventCard,
        EventFilters,
        HomeEventsSlider,
    },

    props: {
        events: {
            type: Array,
            default: () => [],
        },

        titleCount: {
            type: String,
            default: '',
        },

        specs: {
            type: Object,
            default: () => ({}),
        },

        facets: {
            type: Object,
            default: () => ({}),
        },

        values: {
            type: Object,
            default: () => ({
                actual: true,
                is_practice: false,
                type: null,
                totalCount: 0,
            }),
        },

        count: {
            type: Number,
            default: 0,
        },

        eventDescription: {
            type: String,
            default: '',
        },

        practiceDescription: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            eventsVisible: false,
            showFixedFilter: false,
        };
    },

    computed: {
        ...mapState('header', [
            'isVisible',
        ]),

        availableTags() {
            return this.specs?.type?.filter(el => this.facets?.type?.includes(el.value));
        },

        currentDescription() {
            return this.values.is_practice ? this.practiceDescription : this.eventDescription;
        },
    },

    methods: {
        onTypeChange(type) {
            if (this.values?.type?.includes(type)) {
                this.$emit('change', { ...this.values, type: this.values.type.filter(el => el !== type) });
            } else {
                this.$emit('change', { ...this.values, type: [...this.values.type, type] });
            }
        },

        filterIntersect(entry) {
            this.showFixedFilter = !entry[0].isIntersecting;
        },

        eventsIntersect(entry) {
            this.eventsVisible = entry[0].isIntersecting;
        },
    },
};
