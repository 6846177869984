
// components
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';
import HomeMobileSlider from 'landing/components/pages/home/HomeMobileSlider.vue';

export default {
    name: 'HomeOrganizer',

    components: {
        VImageSrcSet,
        HomeMobileSlider,
    },

    props: {
        content: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        cards() {
            return [...this.content?.block_cards|| []].sort((a, b) => a.order - b.order) || [];
        },
    },
};
