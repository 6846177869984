
// utils
import { remToPx, freeze } from '@@/common/assets/js/utils/common-utils';
// scss
import scssVars from '~/assets/scss/shared/_variables.scss';
import PurposeCard from '~/components/common/PurposeCard.vue';
import SignUpCard from '~/components/common/SignUpCard.vue';
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';

export default {
    name: 'HomePurpose',
    components: {
        VImageSrcSet,
        PurposeCard,
        SignUpCard,
    },

    props: {
        purposeData: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            animation: null,
        };
    },

    computed: {
        cards() {
            return [...this.purposeData?.block_cards|| []].sort((a, b) => a.order - b.order) || [];
        },
    },

    watch: {
        '$deviceIs.pc'(val) {
            this.animation.clear();
            requestAnimationFrame(() => {
                this.initAnimations();
                this.animation.scrollTrigger.refresh();
            });
        },
    },

    beforeDestroy() {
        this.animation?.kill();
    },

    async mounted() {
        await freeze(100);
        requestAnimationFrame(this.initAnimations);
    },

    methods: {
        initAnimations() {
            // высчитываем длину анимации, приходится так делать потому что кол-во карточек может варьироваться
            const quoteHeight = window.innerHeight - remToPx(scssVars[this.$deviceIs.pc ? 'header-h' : 'header-h-mobile']);
            const cardsHeight = this.$refs.scrollbox.offsetHeight;

            const coef = cardsHeight / quoteHeight;

            if (!this.animation) {
                this.animation = this.$gsap.timeline({
                    scrollTrigger: {
                        trigger: '#home-purpose',
                        start: 'top top',
                        end: this.$deviceIs.pc ? `+=${quoteHeight * 1.4 + cardsHeight}` : '+=100%',
                        toggleActions: 'restart complete reverse reset',
                        pin: '#home-purpose',
                        pinSpacing: true,
                        scrub: 1,
                    },
                });
            }

            if (this.$deviceIs.pc) {
                this.animation
                    .fromTo('#next-shadow', { opacity: 1 }, { opacity: 0, duration: 1, ease: 'linear' })
                    .to(this.$refs.scrollbox, { y: () => `-=${this.$refs.scrollbox.offsetHeight}`, duration: coef, delay: .2, ease: 'linear' })
                    .to(this.$refs.scrollbox, { delay: .2, duration: 0, x: '+=0' });
            } else {
                this.animation.fromTo('#next-shadow', { opacity: 1 }, { opacity: 0, duration: 1, ease: 'linear' });
            }
        },
    },
};
