// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomePurpose_BAJxY{display:flex;flex-direction:column;height:100dvh;overflow:visible;padding-top:6.4rem;position:relative}@media(max-width:1279.98px){.HomePurpose_BAJxY{height:auto;padding:12rem 1.6rem}}.bg_6-s0h{height:100%;left:0;object-fit:cover;position:absolute;top:0;width:100%;z-index:-1}.bg_6-s0h,.wrapper_r7\\+-g{overflow:hidden}.wrapper_r7\\+-g{align-items:center;display:flex;flex:1 1 auto;font-family:\"Bebas-Neue\",sans-serif;font-size:23rem;font-weight:700;justify-content:center;letter-spacing:-.46rem;line-height:1;position:relative;text-align:center}@media(max-width:1279.98px){.wrapper_r7\\+-g{font-size:5.6rem;letter-spacing:-.116rem;line-height:1}}.cardsWrapper_97c4q{display:grid;grid-template-columns:repeat(2,1fr);left:3.6rem;padding-bottom:3.6rem;position:absolute;right:3.6rem;top:100%;grid-gap:1.2rem;flex-direction:column;will-change:transform}@media(max-width:1279.98px){.cardsWrapper_97c4q{grid-template-columns:repeat(1,1fr);position:static;will-change:auto;grid-gap:1.6rem;margin-top:4rem}}.card_1hX92{height:34rem}@media(max-width:1279.98px){.card_1hX92{height:25.8rem;width:100%}}.cardSign_3zMZp{grid-column:span 2}@media(max-width:1279.98px){.cardSign_3zMZp{grid-column:span 1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"HomePurpose": "HomePurpose_BAJxY",
	"bg": "bg_6-s0h",
	"wrapper": "wrapper_r7+-g",
	"cardsWrapper": "cardsWrapper_97c4q",
	"card": "card_1hX92",
	"cardSign": "cardSign_3zMZp"
};
module.exports = ___CSS_LOADER_EXPORT___;
