// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeFormats_\\+s1j6{display:flex;flex-direction:column;overflow:hidden;position:relative}@media(max-width:1279.98px){.HomeFormats_\\+s1j6{height:auto}}.title_wfMyw{flex:0 0 auto;margin-bottom:3.6rem}@media(max-width:1279.98px){.title_wfMyw{margin-bottom:1.6rem}}.offersWrapper_MH3lm{display:flex;flex:1 0 auto;overflow:visible;padding:0 3.6rem 3.6rem;position:relative}@media(max-width:1279.98px){.offersWrapper_MH3lm{flex-direction:column;padding:0 1.6rem 4.8rem}}.card_mUOSe{flex:0 0 calc(26.7% - 0.94rem);min-height:34rem}.card_mUOSe+.card_mUOSe{margin-left:1.2rem}@media(max-width:1279.98px){.card_mUOSe{flex:0 0 auto;min-height:300px;width:100%}.card_mUOSe+.card_mUOSe{margin-left:0}.card_mUOSe:not(:last-of-type){margin-bottom:1.2rem}}@media(max-width:767.98px){.card_mUOSe{min-height:280px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"HomeFormats": "HomeFormats_+s1j6",
	"title": "title_wfMyw",
	"offersWrapper": "offersWrapper_MH3lm",
	"card": "card_mUOSe"
};
module.exports = ___CSS_LOADER_EXPORT___;
