
import StaggeredTitle from '~/components/common/StaggeredTitle.vue';
import OfferCard from '@/components/common/OfferCard.vue';

export default {
    name: 'HomeFormats',
    components: {
        OfferCard,
        StaggeredTitle,
    },

    props: {
        offersData: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            lines: [
                { label: 'Стоимость', color: '#20123C' },
                { label: 'резидентства', color: '#0F0819' },
            ],
        };
    },
};
