// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeTelegram_avM48{padding-bottom:1.2rem;padding-top:12.4rem;position:relative}@media(max-width:1279.98px){.HomeTelegram_avM48{padding-bottom:4.8rem;padding-top:0}}.header_WCME\\+{align-items:flex-start;display:flex;justify-content:space-between;margin-bottom:6rem}@media(max-width:1279.98px){.header_WCME\\+{margin-bottom:2.4rem}}.title_T8SGJ{font-family:\"Bebas-Neue\",sans-serif;font-size:6.8rem;font-weight:700;letter-spacing:-.136rem;line-height:1}@media(max-width:1279.98px){.title_T8SGJ{font-size:4.4rem;letter-spacing:-.08rem}}.title_T8SGJ span{display:block}.title_T8SGJ span:first-child{color:#848286;font-family:\"Formular\",sans-serif;font-size:1.4rem;font-weight:500;letter-spacing:-.02em;line-height:1.6rem;margin-bottom:2.4rem;text-transform:uppercase}.button_JDeX5{margin-bottom:1.4rem;margin-top:4rem;min-width:19.2rem;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}@media(max-width:1279.98px){.button_JDeX5.v-button{display:none}}.button_JDeX5._mobile_k5ml-{margin-top:2.4rem;min-width:100%;width:100}.button_JDeX5._mobile_k5ml-.v-button{display:none}@media(max-width:1279.98px){.button_JDeX5._mobile_k5ml-.v-button{display:inline-flex}}.list_pBEFD{display:grid;gap:1.2rem;grid-template-columns:repeat(3,minmax(0,1fr))}@media(max-width:1279.98px){.list_pBEFD{gap:.4rem;grid-template-columns:1fr}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"HomeTelegram": "HomeTelegram_avM48",
	"header": "header_WCME+",
	"title": "title_T8SGJ",
	"button": "button_JDeX5",
	"_mobile": "_mobile_k5ml-",
	"list": "list_pBEFD"
};
module.exports = ___CSS_LOADER_EXPORT___;
