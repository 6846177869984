// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeAdBlock_lgWiF,.content_Nuw2m{position:relative}.content_Nuw2m{display:flex;padding:7.8rem 12.8rem 8rem 3.6rem}@media(max-width:1279.98px){.content_Nuw2m{flex-direction:column;padding:7.6rem 3.2rem 8rem}}@media(max-width:767.98px){.content_Nuw2m{padding:5.4rem 2rem 6rem}}.videoWrap_nlblm{height:100%;left:0;position:absolute;top:0;width:100%}.videoWrap_nlblm video{height:100%;object-fit:cover;width:100%}.left_I8NAq{display:flex;flex:1 1 50%;flex-direction:column;margin-right:10.6rem}@media(max-width:1279.98px){.left_I8NAq{flex:unset;margin:0}}.erid_6zqX6{color:#fff9;font-size:12px;font-weight:400;line-height:14px}@media(max-width:1279.98px){.erid_6zqX6{margin-bottom:3rem}}.title_OBlrR{color:#fff;font-family:\"Bebas-Neue\",sans-serif;font-size:6.8rem;font-weight:700;letter-spacing:-.136rem;line-height:1;max-width:40rem}@media(max-width:1279.98px){.title_OBlrR{margin-bottom:3.6rem;max-width:unset}}@media(max-width:767.98px){.title_OBlrR{font-size:4.4rem;line-height:1}}.info_lXqeG{flex:1 1 50%}@media(max-width:1279.98px){.info_lXqeG{flex:unset}}.logo_Va0EH{height:4.4rem;margin-bottom:3rem;transform:translate(-.2rem,-.2rem);width:12.6rem}@media(max-width:1279.98px){.logo_Va0EH{height:5.2rem;margin-bottom:1.4rem;width:12.4rem}}@media(max-width:767.98px){.logo_Va0EH{height:54px;margin-bottom:12px;width:100px}}.description_N8Dle{color:#fff;font-size:1.5rem;font-weight:400;line-height:2.1rem;margin-bottom:3.2rem}@media(max-width:1279.98px){.description_N8Dle{margin-bottom:1.6rem}}@media(max-width:767.98px){.description_N8Dle{margin-bottom:24px}}.button_VUARL{margin-top:auto;width:21.8rem}@media(max-width:1279.98px){.button_VUARL{display:none}}.button_VUARL._adaptive_MX9bQ{display:none}@media(max-width:1279.98px){.button_VUARL._adaptive_MX9bQ{display:flex;width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"HomeAdBlock": "HomeAdBlock_lgWiF",
	"content": "content_Nuw2m",
	"videoWrap": "videoWrap_nlblm",
	"left": "left_I8NAq",
	"erid": "erid_6zqX6",
	"title": "title_OBlrR",
	"info": "info_lXqeG",
	"logo": "logo_Va0EH",
	"description": "description_N8Dle",
	"button": "button_VUARL",
	"_adaptive": "_adaptive_MX9bQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
