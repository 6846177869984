// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeResidence_hBgBj{display:flex;flex-direction:column;overflow:hidden;position:relative}.hero_5d3eK{height:calc(100vh - 6.4rem)}@media(max-width:767.98px){.hero_5d3eK{height:100%}}.slider_0DbKJ{bottom:0;left:0;position:absolute;transform:translateY(100%);width:100%}@media(max-width:1279.98px){.slider_0DbKJ{transform:translateY(0)}}.title_cUMu1{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1;margin:2rem;text-transform:uppercase}@media(max-width:767.98px){.title_cUMu1{display:none}}.titleMobile_GMWHJ{display:none;font-family:\"Bebas-Neue\",sans-serif;font-size:1.8rem;font-weight:400;letter-spacing:-.02em;line-height:1em;text-transform:uppercase}@media(max-width:767.98px){.titleMobile_GMWHJ{display:inline}}.topAnchor_V4Zch{height:1px;position:absolute;top:-6.4rem;width:100%}@media(max-width:767.98px){.topAnchor_V4Zch{top:-5.2rem}}.bottomAnchor_tGlXa{bottom:-22.8rem;height:1px;position:absolute;width:100%}.wrapper_aUsou{display:flex;justify-content:space-between;padding:1.6rem}.buttons_WMIzo,.wrapper_aUsou{align-items:center}.buttons_WMIzo{display:none;flex-shrink:0;margin:-1rem;transform:translateX(4px)}@media(max-width:767.98px){.buttons_WMIzo{display:flex}}.button_b1JuE{color:#fff;cursor:pointer;display:block;padding:1rem}.button_b1JuE._next_u2V0j .icon_-k33E{transform:rotate(180deg)}.button_b1JuE:disabled{opacity:.6}.icon_-k33E{height:1.8rem;width:1.8rem}.separator_J7t3U{background-color:#3a363d;display:block;height:1.8rem;margin:-4px -2px 0;width:1px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"HomeResidence": "HomeResidence_hBgBj",
	"hero": "hero_5d3eK",
	"slider": "slider_0DbKJ",
	"title": "title_cUMu1",
	"titleMobile": "titleMobile_GMWHJ",
	"topAnchor": "topAnchor_V4Zch",
	"bottomAnchor": "bottomAnchor_tGlXa",
	"wrapper": "wrapper_aUsou",
	"buttons": "buttons_WMIzo",
	"button": "button_b1JuE",
	"_next": "_next_u2V0j",
	"icon": "icon_-k33E",
	"separator": "separator_J7t3U"
};
module.exports = ___CSS_LOADER_EXPORT___;
