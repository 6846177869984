// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResidenceHero_vu9wH{align-items:center;display:flex;justify-content:center;overflow:hidden;position:relative;-webkit-user-select:none;user-select:none}@media(max-width:767.98px){.ResidenceHero_vu9wH{justify-content:flex-start}}.wrapper_KV9ol{max-width:70rem;text-align:center}@media(max-width:767.98px){.wrapper_KV9ol{padding:1.6rem;text-align:left}}.label_p4ZIZ{color:#848286;font-size:1.4rem;font-weight:500;line-height:1.6rem;margin-bottom:3.2rem;text-transform:uppercase}@media(max-width:767.98px){.label_p4ZIZ{margin-bottom:2.4rem}}.title_DvTjL{font-family:\"Bebas-Neue\",sans-serif;font-size:6.8rem;font-size:7.2rem;font-weight:700;letter-spacing:-.136rem;line-height:1;margin-bottom:3.6rem}@media(max-width:767.98px){.title_DvTjL{font-size:3.6rem;font-weight:400;letter-spacing:-.02em;line-height:3.6rem;margin-bottom:2.4rem}}.description_bGKUa{font-size:1.5rem;font-weight:400;line-height:2.1rem}@media(max-width:767.98px){.description_bGKUa{color:#cecdcf;font-size:1.4rem;font-weight:400;line-height:1.9rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"ResidenceHero": "ResidenceHero_vu9wH",
	"wrapper": "wrapper_KV9ol",
	"label": "label_p4ZIZ",
	"title": "title_DvTjL",
	"description": "description_bGKUa"
};
module.exports = ___CSS_LOADER_EXPORT___;
