// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeOpportunities_XFFXO{display:flex;flex-direction:column;height:calc(100dvh - 6.4rem);margin-bottom:23rem;max-height:100dvh;overflow:hidden;position:relative}@media(max-width:1279.98px){.HomeOpportunities_XFFXO{height:auto;margin-bottom:12rem;max-height:none}}.title_lYtxO{flex:0 0 auto;margin-bottom:3.6rem}.sliderWrapper_FLET3{flex:1 0 auto;overflow:hidden;padding:0 3.6rem 3.6rem;position:relative}@media(max-width:1279.98px){.sliderWrapper_FLET3{padding:0 1.6rem 1.6rem}}.slider_G5vJf{display:flex;height:100%;will-change:transform}@media(max-width:1279.98px){.slider_G5vJf{flex-direction:column}}.card_3zI0n{flex:0 0 auto;height:100%}.card_3zI0n:not(:last-of-type){margin-right:1.2rem}@media(max-width:1279.98px){.card_3zI0n{height:auto;min-height:34rem;width:100%}.card_3zI0n:not(:last-of-type){margin-bottom:1.2rem;margin-right:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"HomeOpportunities": "HomeOpportunities_XFFXO",
	"title": "title_lYtxO",
	"sliderWrapper": "sliderWrapper_FLET3",
	"slider": "slider_G5vJf",
	"card": "card_3zI0n"
};
module.exports = ___CSS_LOADER_EXPORT___;
