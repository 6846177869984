
// components
import HomeTelegramCard from '~/components/pages/home/HomeTelegramCard.vue';

// constants
const TELEGRAM_CARDS = [
    {
        icon: 'bell',
        title: 'анонсы<br><span>актуальных мероприятий</span>',
    },
    {
        icon: 'case',
        title: 'кейсы<br><span>от резидентов клуба<br>и экспертов рынка</span>',
    },
    {
        icon: 'photo',
        title: 'фотоотчеты<br><span>с прошедших мероприятий</span>',
    },
];

const TEMPLATE_TEXT = {
    buttonTemplate: 'перейти в telegram-канал',
    titleTemplate: '<span>свежие анонсы</span><span>Telegram-канал клуба <br> @club_dvizhenie</span>',

};

export default {
    name: 'HomeTelegram',

    components: {
        HomeTelegramCard,
    },

    props: {
        socials: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            templateText: TEMPLATE_TEXT,
        };
    },

    computed: {
        link() {
            return this.socials.find(item => item.name === 'Telegram' || item.name === 'telegram')?.link ?? '';
        },

        telegramCards() {
            return TELEGRAM_CARDS;
        },
    },
};
