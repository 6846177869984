
// Components
import VButton from 'landing/components/ui/button/VButton';
import VImage from '@@/common/components/ui/images/VImage';

export default {
    name: 'HomeAdBlock',

    components: {
        VImage,
        VButton,
    },

    props: {
        ad: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        videoSrc() {
            if (this.$deviceIs.mobile) {
                return this.ad?.video_mobile || '';
            }
            if (this.$deviceIs.tablet) {
                return this.ad?.video_tablet || '';
            }

            return this.ad?.video || '';
        },
    },
};
