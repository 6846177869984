// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeEventsSlider_w4owK{cursor:none;overflow:hidden;position:relative}.HomeEventsSlider_w4owK .VCursor_drag .icon_a2RO1:first-child{transform:translateX(.5rem)}.HomeEventsSlider_w4owK .VCursor_drag .icon_a2RO1:last-child{transform:translateX(-.5rem) rotate(180deg)}.HomeEventsSlider_w4owK._unclickable_g0HSD .card_JmE9Q,.HomeEventsSlider_w4owK._unclickable_g0HSD .slider_57R4E{cursor:none}.slider_57R4E{height:100%;position:relative;width:100%}.slider_57R4E.swiper .swiper-slide{max-width:37rem}.slider_57R4E.swiper .swiper-slide:not(:last-child){margin-right:12px}.card_JmE9Q._last_mV2xc{margin-right:1.2rem}@media(max-width:767.98px){.cursorWrapper_1ngwt{display:none}}.cursor_FZR9G{align-items:center;background:#fff;border-radius:50%;display:flex;height:4.8rem;justify-content:center;transition:opacity .3s ease;width:4.8rem}.cursor_FZR9G .icon_a2RO1{transition:transform .3s ease}.cursor_FZR9G .icon_a2RO1:last-child{transform:rotate(180deg)}.cursor_FZR9G._hidden_U5cCN{opacity:0}.icon_a2RO1{color:#09040d;height:1.8rem;width:1.8rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"HomeEventsSlider": "HomeEventsSlider_w4owK",
	"icon": "icon_a2RO1",
	"_unclickable": "_unclickable_g0HSD",
	"card": "card_JmE9Q",
	"slider": "slider_57R4E",
	"_last": "_last_mV2xc",
	"cursorWrapper": "cursorWrapper_1ngwt",
	"cursor": "cursor_FZR9G",
	"_hidden": "_hidden_U5cCN"
};
module.exports = ___CSS_LOADER_EXPORT___;
