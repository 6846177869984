
// components
import HomeFeedbackCard from '~/components/pages/home/HomeFeedbackCard.vue';
import VButton from '~/components/ui/button/VButton.vue';
import VIcon from '~/components/ui/icon/VIcon.vue';
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';

export default {
    name: 'HomeFeedback',

    components: {
        VImageSrcSet,
        VIcon,
        VButton,
        HomeFeedbackCard,
    },

    props: {
        feedbacks: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            slider: null,
        };
    },

    watch: {
        '$deviceIs.pc'() {
            this.slider?.destroy();

            this.initSlider();
        },
    },

    mounted() {
        this.initSlider();
    },

    beforeDestroy() {
        this.slider?.destroy();
        this.slider = null;
    },

    methods: {
        initSlider() {
            this.slider = new this.$Swiper(this.$refs.slider, {
                slidesPerView: this.$deviceIs.pc ? 3 : 1.1,
                loop: this.feedbacks?.length >= 6,
                spaceBetween: this.$deviceIs.pc ? 12 : 4,
                centeredSlides: !this.$deviceIs.pc,

                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },

                modules: [
                    this.$SwiperModules.Autoplay,
                ],
            });
        },
    },
};
