// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HomeFeedbackCard_\\+FOfT{background-color:#161119;display:flex;flex-direction:column;min-width:44.8rem;padding:2.4rem}@media(max-width:1279.98px){.HomeFeedbackCard_\\+FOfT{min-width:auto;padding:2rem}}.scrollbox_YTbKr{flex:1 1 auto;margin-bottom:2.4rem}.quote_eUrHe{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1;-webkit-user-select:none;user-select:none}.personal_0QDWz{align-items:center;display:flex;margin-top:auto}.image_GOouy{display:block;height:4rem;margin-right:1.6rem;width:4rem}.description_6ltHg{display:flex;flex-direction:column}.name_aE8nG{font-size:1.5rem;line-height:2.1rem}.name_aE8nG,.position_EYE7M{font-weight:400;-webkit-user-select:none;user-select:none}.position_EYE7M{color:#848286;font-size:12px;line-height:14px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"HomeFeedbackCard": "HomeFeedbackCard_+FOfT",
	"scrollbox": "scrollbox_YTbKr",
	"quote": "quote_eUrHe",
	"personal": "personal_0QDWz",
	"image": "image_GOouy",
	"description": "description_6ltHg",
	"name": "name_aE8nG",
	"position": "position_EYE7M"
};
module.exports = ___CSS_LOADER_EXPORT___;
