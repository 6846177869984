
// components
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';

import VScrollBox from '~/components/ui/scrollbox/VScrollBox.vue';

export default {
    name: 'HomeFeedbackCard',

    components: {
        VScrollBox,
        VImageSrcSet,
    },

    props: {
        feedbackData: {
            type: Object,
            default: () => ({}),
        },
    },
};
