
import VButton from 'landing/components/ui/button/VButton.vue';
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';
import { freeze } from '@@/common/assets/js/utils/common-utils';

export default {
    name: 'HomeVisitEvents',
    components: { VImageSrcSet, VButton },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            animation: null,
        };
    },

    watch: {
        '$deviceIs.pc'(val) {
            this.animation?.clear();
            requestAnimationFrame(() => {
                this.initAnimation();
                this.animation?.scrollTrigger?.refresh();
            });
        },
    },

    beforeDestroy() {
        this.animation?.kill();
    },

    async mounted() {
        await freeze(100);
        requestAnimationFrame(this.initAnimation);
    },

    methods: {
        initAnimation() {
            const cards = this.$refs.card;

            this.animation = this.$gsap.timeline({
                scrollTrigger: {
                    trigger: this.$el,
                    start: 'top bottom',
                    end: 'bottom-=30% bottom',
                    scrub: 1,
                },
            });

            this.animation.to(cards, {
                y: 0,
                opacity: 1,
                stagger: 0.2,
            });
        },
    },
};
