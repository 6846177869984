

export default {
    name: 'HomeTelegramCard',

    props: {
        card: {
            type: Object,
            default: () => ({}),
        },
    },
};
