
// Vuex
import { mapState } from 'vuex';

// utils
import { remToPx, freeze } from '@@/common/assets/js/utils/common-utils';

// libs
import SplitType from 'split-type';

// components
import VButton from '~/components/ui/button/VButton.vue';
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';

export default {
    name: 'HomeHero',
    components: {
        VButton,
        VImageSrcSet,
    },

    props: {
        mainPageData: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            splitTitleInstance: null,
            animation: null,
        };
    },

    computed: {
        ...mapState('header', [
            'partnerLogoIcon',
        ]),

        seoTitle() {
            return this.mainPageData?.title?.replace(/(<([^>]+)>)/ig, '');
        },
    },

    watch: {
        '$deviceIs.pc'(val) {
            if (val) {
                this.animateElements();
            }
        },
    },

    async mounted() {
        await this.$nextTick();

        this.splitTitleInstance = new SplitType(this.$refs.title, {
            lineClass: this.$style.titleLine,
            wordClass: this.$style.titleWord,
        });

        if (this.$deviceIs.pc) {
            await freeze(300);

            this.animateElements();
        }
    },

    beforeDestroy() {
        this.splitTitleInstance = null;
        this.animation?.kill();
    },

    methods: {
        handleMouseMove(e) {
            const ivalueX= (e.pageX - window.innerWidth/2) / 5;
            const ivalueY= e.pageY / 20;

            if (this.$refs.backlight && this.$deviceIs.pc) {
                this.$refs.backlight.style.transform = `translate3d(calc(-50% + ${ivalueX}px), ${ivalueY}px, 0)`;
            }
        },

        animateElements() {
            this.animation = this.$gsap.timeline();

            this.animation
                // прозрачность заголовка убирается
                .fromTo(this.$refs.title, { opacity: 0 }, { opacity: 1, duration: .8 })
                // слова снизу вверх выезжают
                .fromTo(`.${this.$style.titleWord}`, { y: '100%' }, { y: 0, duration: .8 }, 0)
                // прозрачность кнопки убирается
                .fromTo(this.$refs.btn.$el, { opacity: 0 }, { opacity: 1, duration: .8 }, .4)
                // прозрачность описания убирается
                .fromTo(this.$refs.description, { opacity: 0 }, { opacity: 1, duration: .8 }, .4)
                // кнопка смещается вверх
                .fromTo(this.$refs.btn.$el, { y: remToPx('2.4rem') }, { y: 0, duration: .4 }, .4)
                // описание смещается вверх
                .fromTo(this.$refs.description, { y: remToPx('2.4rem') }, { y: 0, duration: .4 }, .4)
                // прозрачность картинки убирается
                .fromTo(this.$refs.image, { opacity: 0 }, { opacity: 1, duration: 1.2 }, .6)
                // прозрачность свечения убирается
                .fromTo(this.$refs.backlight, { opacity: 0 }, { opacity: 1, duration: 1.2 }, .8);
        },
    },
};
